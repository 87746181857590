import React from "react";

const Header = () => {
    return(
        <header>
            <h3>Admin Console</h3>
        </header>
    )
}

export default Header