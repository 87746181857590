import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Bars } from 'react-loading-icons';
import Header from './Header';
import { MdDeleteForever, MdEdit } from "react-icons/md";

const Dashboard = () => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN")
    const navigate = useNavigate()

    const prodImg = useRef("")
    const logo = useRef("")

    const [name, setName] = useState("")
    const [link, setLink] = useState("")
    const [cat, setCat] = useState("")
    const [image, setImage] = useState("")
    const [icon, setIcon] = useState("")
    const [id, setId] = useState(null)

    const [data, setData] = useState([])

    const [submitting, setSubmitting] = useState(false)
    const [submitErr, setSubmitErr] = useState(null)
    const [success, setSuccess] = useState(false)

    const [moreLoading, setMoreLoading] = useState(false)
    const [delLoading, setDelLoading] = useState(false)

    const checkLogin = async () => {
        setLoading(true)
        setError(null)
        await axios.get(`${process.env.REACT_APP_API_URL}/admin`, {
        headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`
        }
        })
        .then(({data}) => {
        setLoading(false)
        if (!data.status){
            navigate("/")
        }else{
            setData(data.data)
        }
        })
        .catch(err => {
            setLoading(false)
            setError(err.message)
        })
    }
    const submit = () => {
        setSubmitting(true)
        setSubmitErr(null)
        setSuccess(false)
        const formData = new FormData()
        formData.append('id', id)
        formData.append("image", image)
        //formData.append("icon", icon)
        formData.append("name", name)
        formData.append("link", link)
        formData.append("cat", cat)

        if (name == "" || link == "" || cat == "" || (image == "" && !id)){
            setSubmitErr("Some fields are empty")
            setSubmitting(false)
        }else{
            axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
                headers:{
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                if (res.data.status){
                    setName("")
                    setLink("")
                    setImage("")
                    setCat("")
                    setSuccess(true)
                    if (!id){
                        setData(prev => {
                            return [{
                                id: res.data.id,
                                file: res.data.file,
                                logo: null,
                                rating: null,
                                name:name,
                                cat: cat,
                                link: link,
                                stamp: null

                            }, ...prev]
                        })
                    }else{
                        data.forEach(value => {
                            if (value.id === parseInt(res.data.details.id)){
                                if (res.data.details.file){
                                    value.file = res.data.details.file
                                }else{
                                    value.file = value.file
                                }
                                value.name = res.data.details.name
                                value.link = res.data.details.link
                                value.cat = res.data.details.cat
                            }
                        })
                        // setData(prev => {
                        //     return data
                        // })
                        //console.log(arr)
                    }
                    setId(null)
                }else{
                    setSubmitErr(res.data.message)
                }
                setSubmitting(false)
            })
            .catch(err => {
                console.log(err)
                setSubmitting(false)
                setSubmitErr(err.message)
            })
        }
    }
    const more = () => {
        setMoreLoading(true)
        const id = data[data.length-1].id
        axios.get(`${process.env.REACT_APP_API_URL}/admin`, {
            headers: {
                Authorization: `Bearer ${ACCESS_TOKEN}`
            },
            params: {
                id: id
            }
        })
        .then(({data}) => {
            setMoreLoading(false)
            setData(prev => {
                return [...prev, ...data.data]
            })
        })
        .catch(err => {
            setMoreLoading(false)
        })  
    }

    const remove = (id, index) => {
        setDelLoading(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/delete`, {
            headers: {
                Authorization: `Bearer ${ACCESS_TOKEN}`
            },
            data: {
                id: id
            }
        })
        .then(({data}) => {
            setDelLoading(false)
            console.log(index)
            setData(arr => {
                return arr.slice(0, index).concat(arr.slice(index + 1))
            })
        })
        .catch(err => {
            setDelLoading(false)
        })
    }

    const edit = (value) => {
        window.scrollTo(0, 0)
        setId(value.id)
        //setImage(value.file)
        setName(value.name)
        setLink(value.link)
        setCat(value.cat)
    }
    useEffect(() => {
        checkLogin()
    }, [])
    return (
        <>
        <Header />
        <div className="App">
        <div style={{ flex:1 }}>
            {loading
            ?
            <Bars stroke='#cccccc'/>
            :
            error
            ?
            <div>
            <h3>{error}</h3>
            <button onClick={checkLogin} style={{ maxWidth:150 }}>Retry</button>
            </div>
            :
            <>
            <form method='post' encType='multipart/form-data'>
                {success 
                &&
                <div className="success">Product added successfully</div>
                }
                <h3>Add a new product</h3>
                <div style={{  }}>
                    <button type='button' onClick={() => prodImg.current.click()} style={{ background:'white', height:'220px', flex:1, minWidth:'220px', width:'100%', maxWidth:'280px' }}>
                        <label style={{ fontWeight:'bold' }}>{image ? 'Selected'  : 'Upload Image'}</label>
                    </button>
                    {/* <button type='button' onClick={() => logo.current.click()} style={{ background:'white', height:'100px', width:'100px' }}>
                        <label style={{ fontWeight:'bold' }}>{image ? 'Selected'  : 'Logo +'}</label>
                    </button> */}
                </div>
                <input onChange={(e) => {
                    setImage(e.target.files[0])
                    }} ref={prodImg} style={{ display:'none' }} type="file" name='file' />
                <input onChange={(e) => setName(e.target.value)} type="text" name='name' placeholder='Product Name' value={name}/>
                <input onChange={(e) => setLink(e.target.value)} type="text" name='link' placeholder='Product Link' value={link}/>
                
                <input onChange={(e) => setIcon(e.target.files[0])} ref={logo} style={{ display:'none' }} type="file" name='file' />
                <input onChange={(e) => setCat(e.target.value)} type="text" name='cat' placeholder='Product Category' value={cat}/>
                {
                    submitErr 
                    &&
                    <div className='err'>{submitErr}</div>
                }
                <button onClick={submit} type='button'>
                    {submitting
                    ?
                    <Bars style={{ width:21, height:21 }} />
                    :
                    "Submit"
                    }
                </button>
            </form> 
            <div style={{ padding:0, background:'white', marginTop:50, display:'flex', flexDirection:'column', alignItems:'center' }}>
                <h3>View Products</h3>
                {
                    data.map((value, index) => (
                        <div key={value.id} style={{width:"90%", maxWidth:600, display:'flex', gap:10, margin:7, marginBottom:0, justifyContent:'space-between', alignItems:'center', border:"1px solid lightgrey", padding:10, borderRadius:5, background:'#f5f5f5' }}>
                            <div style={{ display:'flex', gap:10, alignItems:'center', flex:1 }}>
                                <img width={30} height={30} src={`${process.env.REACT_APP_API_URL}/uploads/${value.file}`} />
                                <div style={{ fontWeight:'bold', flex:1, width:"100%" }}>{value.name} 
                                    <div>
                                        <span contentEditable={true} onClick={(r) => {
                                            r.target.parentElement.childNodes[1].style.display='inline'
                                        }} style={{ backgroundColor:'aliceblue', border:"1px solid cadetblue", marginLeft:7, color:'cadetblue', borderRadius:3, fontSize:'small', padding:"0 7px", outline:"darkcyan" }}>{!value.rating ? "0.00" : value.rating} &#9733;
                                        </span>
                                        <span onClick={(btn) => {
                                            btn.target.style.display = "none";
                                            const rating = (btn.target.parentElement.childNodes[0].innerText).replace("&#9733;", "").replace("★", "").trim()
                                            axios.post(`${process.env.REACT_APP_API_URL}/rate`, {
                                                id:value.id,
                                                rating: rating
                                            }, 
                                            {
                                                headers: {
                                                    Authorization: `Bearer ${ACCESS_TOKEN}`
                                                }
                                            })
                                        }} style={{ cursor:'pointer', backgroundColor:'cadetblue', border:"1px solid cadetblue", display:'none', marginLeft:7, color:'white', borderRadius:3, fontSize:'small', padding:"0 7px", outline:'none' }} >
                                            done
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => edit(value)} style={{width:'40px', height:'40px', border:0, padding:5, borderRadius:'50%', background:"transparent", color:'#278dc3' }}>
                                <MdEdit />
                            </button>
                            <button onClick={() => remove(value.id, index)} style={{width:'40px', height:'40px', border:0, padding:5, borderRadius:'50%', background:"transparent", color:'darkred' }}>
                            {delLoading
                                ?
                                <Bars style={{ width:21, height:21 }} />
                                :
                                <MdDeleteForever />
                            }
                            </button>
                        </div>
                    ))
                }
                <button onClick={more} style={{ marginTop:20, marginBottom:20 }}>
                    {moreLoading
                    ?
                    <Bars style={{ width:21, height:21 }} />
                    :
                    "View more"
                    }
                </button>
            </div>
            </>
            }
        </div>
        </div>
        </>
    )
}

export default Dashboard