import logo from './logo.svg';
import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'
import {Bars} from 'react-loading-icons'
import { Navigate, useNavigate } from 'react-router-dom'
import Header from './Header';

function App() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [loadingLogin, setLoadingLogin] = useState(false)
  const [loginError, setLoginError] = useState(null)

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN")
  const navigate = useNavigate()
  const checkLogin = async () => {
    setLoading(true)
    setError(null)
    await axios.get(`${process.env.REACT_APP_API_URL}/admin`, {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`
      }
    })
    .then(({data}) => {
      setLoading(false)
      if (data.status){
        navigate("/dashboard")
      }
    })
    .catch(err => {
      setLoading(false)
      setError(err.message)
    })
  }

  const login = (e) => {
    e.preventDefault()
    setLoadingLogin(true)
    setLoginError(null)

    const token = random_string(40)
    axios.post(`${process.env.REACT_APP_API_URL}/login`, {
      token: token,
      username: username,
      password: password
    })
    .then(({data}) => {
      setLoadingLogin(false)
      console.log(data)
      if (data.status){
        localStorage.setItem("ACCESS_TOKEN", token)
        navigate("/dashboard")
      }else{
        setLoginError(data.message)
      }
    })
    .catch(err => {
      setLoadingLogin(false)
      setLoginError(err.message)
    })
  }

  const random_string = (num) => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890_"
    let result = ''
    for (let i = 0; i < num; i++){
        result += chars[Math.floor(Math.random()*chars.length)]
    }
    return result
}

  useEffect(() => {
    checkLogin()
  }, [])
  return (
    <>
    <Header />
    <div className="App">
      <div style={{ flex:1 }}>
        {loading
        ?
        <Bars stroke='#cccccc'/>
        :
        error
        ?
        <div>
          <h3>{error}</h3>
          <button onClick={checkLogin} style={{ maxWidth:150 }}>Retry</button>
        </div>
        :
        <form onSubmit={(e) => login(e)}>
          {
            loginError
            &&
            <div className='login-error'><span>{loginError}</span></div>
          }
          
          <input onChange={(e) => setUsername(e.target.value)} type="text" name="username" placeholder='Username' value={username} />
          <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" placeholder="Password" value={password} />
          <button role='button' type="submit">
            {
              loadingLogin
              ?
              <Bars style={{ width:21, height:21 }} />
              :
              <span>Login</span>
            }
          </button>
        </form> 
        }
      </div>
    </div>
    </>
  );
}

export default App;
